import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { httpRelatedEnterprisesApi } from 'modules/enterprise/related-enterprise/infraestructure/api/httpRelatedEnterprisesApi';
import { RelatedEnterprisesDataProvider } from 'modules/enterprise/related-enterprise/infraestructure/store/relatedEnterpriseStore';
import { isCocircularManager } from 'utils/helpers/user.helpers';
import { PromotionBilleablesProvider, httpBilleablesApi } from 'modules/billeables';
import userServices from '../../api/user/user.services';
import promotionServices from '../../api/promotion/promotion.services';
import directionServices from '../../api/directions/directions.services';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import Stepper from '../../components/BasicComponents/Steppers/Stepper';
import Spinner from '../../components/BasicComponents/Spinner/Spinner';
import ResultadoCarga from '../../components/BasicComponents/Messages/ResultadoCarga';
import { allFieldsComplete, differenceArrays } from '../../utils/helpers/general.helpers';
import { INITIAL_STATE_DIRECTION, INITIAL_STATE_PROMOTION } from './newPromotion.utils';
import './NewPromotion.scss';
import StepperContent from './StepperContent';

const NewPromotion = (props) => {
    const [t] = useTranslation();
    const history = useHistory();

    const steps = [
        { id: 0, title: t('promotionInformation', { ns: 'newPromotion' }) },
        { id: 1, title: t('Empresas relacionadas', { ns: 'newPromotion' }) },
        { id: 2, title: t('productsAndServices.name', { ns: 'newPromotion' }) },
        { id: 3, title: t('users', { ns: 'newPromotion' }) }
    ];

    const [currentStep, setCurrentStep] = useState(0);
    const [promotion, setPromotion] = useState(INITIAL_STATE_PROMOTION);
    const [direction, setDirection] = useState(INITIAL_STATE_DIRECTION);
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [validation, setValidation] = useState(false);
    const [message, setMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [oldUsers, setOldUsers] = useState([]);

    const handleInputChange = ({ target }) => {
        setPromotion({
            ...promotion,
            [target.name]: target.value
        });
    };

    const getPromotionTypeDirection = async () => {
        let filter = `?nombre.equals=Promoción`;
        const typePromotionDirection = await directionServices.getTypeDirection(filter);
        return typePromotionDirection[0];
    };

    const createMessage = (newPromotion, updating = false) => {
        if (newPromotion !== undefined && newPromotion.status === 201 && !updating) {
            setMessage({ text: t('successCreatePromotion', { ns: 'newPromotion' }), success: true });
        } else if (newPromotion !== undefined && updating) {
            setMessage({ text: t('successUpdatePromotion', { ns: 'newPromotion' }), success: true });
        } else {
            setMessage({ text: t('errorCreatePromotion', { ns: 'newPromotion' }), success: false });
        }
        setLoading(false);
    };

    const asignUsersToPromotion = async (idPromotion) => {
        const newUsers = differenceArrays(users, oldUsers);
        let usersToAssign = [];
        newUsers.forEach(async (user) => {
            user.promotionIds = [...user.promotionIds, idPromotion];
            await userServices.editUser(user);
            usersToAssign.push(user);
        });
        await Promise.all(usersToAssign);
    };

    const createDirection = async () => {
        const typePromotionDirection = await getPromotionTypeDirection();
        const objDirection = { ...direction, tipo: typePromotionDirection };
        const newDirection = await directionServices.createDirection(objDirection);

        if (newDirection !== undefined) {
            console.log('dirección creada');
            setDirection({
                municipio: newDirection.data.municipio,
                postalCode: newDirection.data.postalCode,
                direccion: newDirection.data.direccion
            });
            return newDirection.data;
        }
    };

    const createNewPromotion = async (relatedEnterprises) => {
        const newDirection = await createDirection();
        Object.assign(promotion, { direccion: newDirection, relatedEnterprises });

        console.log({ promotion });

        try {
            console.log('entro al try');
            const newPromotion = await promotionServices.createPromotion(promotion);
            console.log({ newPromotion });

            if (users.length !== 0) {
                await asignUsersToPromotion(newPromotion.data.id);
            }
            const searchPromotion = await promotionServices.getPromotionById(newPromotion.data.id);
            createMessage(newPromotion);
            setPromotion({ ...searchPromotion, relatedEnterprises });
            return newPromotion;
        } catch (error) {
            console.error(error?.response?.data);
        }
    };

    const validateIfIsSameDirection = () => {
        if (
            promotion.direccion.municipio.id === direction.municipio.id &&
            promotion.direccion.postalCode === direction.postalCode &&
            promotion.direccion.direccion === direction.direccion
        ) {
            return true;
        } else {
            return false;
        }
    };

    const updatePromotion = async (relatedEnterprises) => {
        let updatePromotion;
        if (validateIfIsSameDirection()) {
            updatePromotion = await promotionServices.editPromotion({ ...promotion, relatedEnterprises });
        } else {
            const createNewDirection = await createDirection();
            Object.assign(promotion, { direccion: createNewDirection });
            updatePromotion = await promotionServices.editPromotion(promotion);
        }
        if (users.length !== 0) {
            await asignUsersToPromotion(updatePromotion.data.id);
        }
        createMessage(updatePromotion, true);
        const searchPromotion = await promotionServices.getPromotionById(updatePromotion.data.id);
        setPromotion({
            ...searchPromotion,
            usuarios: promotion.usuarios,
            relatedEnterprises
        });
        return updatePromotion;
    };

    const validateForm = (relatedEnterprises) => {
        return validationForm(relatedEnterprises) && validationNIMA();
    };

    const createPromotion = async (relatedEnterprises) => {
        setSubmitting(true);
        setLoading(true);

        let newPromotion = null;

        const isValid = validateForm(relatedEnterprises);

        try {
            if (!isValid) throw new Error('invalid form');

            let promotionRequest = null;

            if (!promotion?.id) {
                promotionRequest = await createNewPromotion(relatedEnterprises);
                newPromotion = promotionRequest?.data;
            } else {
                promotionRequest = await updatePromotion(relatedEnterprises);
                newPromotion = promotionRequest?.data;
            }
        } catch (error) {
            console.log(error);
        }

        setSubmitting(false);
        setLoading(false);

        return newPromotion;
    };

    const validationNIMA = () => {
        if (!promotion.nima) return true;

        const regexNIMA = '[0-9]{10}';
        const isNIMAValid = promotion.nima.match(regexNIMA);
        if (isNIMAValid === null) {
            setMessageNotComplete(t('nimaNotValid', { ns: 'errors' }));
            return false;
        } else {
            setMessageNotComplete('');
            return true;
        }
    };

    const searchPromotionToEdit = async () => {
        const promotionToEdit = await promotionServices.getPromotionById(props.match.params.id);
        delete promotionToEdit.delegacion;
        const users = await userServices.getUsersByPromoId(props.match.params.id);
        setPromotion({ ...promotionToEdit });
        setUsers(users);
        setOldUsers([...users]);
        setDirection({
            direccion: promotionToEdit.direccion.direccion,
            municipio: promotionToEdit.direccion.municipio,
            postalCode: promotionToEdit.direccion.postalCode
        });
    };

    useEffect(() => {
        let mounted = true;
        if (mounted && props.match.params.id !== undefined) {
            searchPromotionToEdit();
        }
        return () => {
            mounted = false;
        };
    }, [props.match.params.id]);

    const fieldsRequired = {
        direccion: direction.direccion,
        municipio: direction.municipio,
        postalCode: direction.postalCode,
        nombre: promotion.nombre,
        num: promotion.num,
        estadoPromocion: promotion.estadoPromocion,
        promotionType: promotion.promotionType,
        surface: promotion.surface,
        fechaFin: promotion.fechaFin,
        fechaInicio: promotion.fechaInicio,
        endDateCocircular: promotion.endDateCocircular,
        startDateCocircular: promotion.startDateCocircular
    };

    const validationForm = (relatedEnterprises) => {
        let mandatoryFields = fieldsRequired;
        const errors = [];

        if (promotion.id === undefined) {
            if (promotion.siteVisitNum !== null && promotion.siteVisitNum !== '' && promotion.siteVisitNum !== 0) {
                mandatoryFields = Object.assign(mandatoryFields, { startDateVisits: promotion.startDateVisits });
            }
        }

        if (promotion.selloVerde) {
            mandatoryFields = Object.assign(mandatoryFields, { porcentajeSelloVerde: promotion.porcentajeSelloVerde });
        }

        const invalidFields = !allFieldsComplete(mandatoryFields);

        if (invalidFields) {
            errors.push(t('completeForm', { ns: 'newPromotion' }));
        }

        const cocircularManagers = users?.filter(isCocircularManager) || [];

        if (cocircularManagers.length === 0) {
            errors.push(t('noCocircularManagerAdded', { ns: 'newPromotion' }));
        }

        const mainEnterprises = relatedEnterprises.filter((relatedEnterprise) => relatedEnterprise.mainEnterprise);
        const clientEnterprises = relatedEnterprises.filter((relatedEnterprise) => relatedEnterprise.client);

        // TODO: esto de abajo son reglas de negocio, deberian moverse a dominio somehow?
        if (mainEnterprises.length === 0) {
            errors.push(t('atLeastOneMainEnterprise', { ns: 'newPromotion' }));
        }

        if (mainEnterprises.length > 1) {
            errors.push(t('tooManyMainEnterprise', { ns: 'newPromotion' }));
        }

        if (clientEnterprises.length === 0) {
            errors.push(t('atLeastOneClientEnterprise', { ns: 'newPromotion' }));
        }

        const isValid = errors.length === 0;
        const message = isValid ? '' : errors.join(' ');

        setMessageNotComplete(message);
        setValidation(!isValid);

        return isValid;
    };

    const handleStepChange = (step) => {
        const id = props.match.params.id;
        const isEdit = id !== undefined;
        const isUsers = step === 3;

        // If we are editing a promotion and we are in the users step, we redirect to the users tab
        if (isEdit && isUsers) history.push(`/promotion/${id}/expedient/users`);

        setCurrentStep(step);
    };

    //check if promocion.id === idUrl
    const checkIds = () => {
        return props.promocion?.id === Number(props.match.params.id) ? true : false;
    };

    useEffect(() => {
        if (props.match.params.id && props.promocion?.id) {
            if (checkIds() === false) {
                history.push(`/promotion/${props.promocion?.id}/settings`);
            }
        }
    }, [props?.promocion?.id]);

    const Wrapper = props.layoutless ? React.Fragment : Layout;

    return (
        <RelatedEnterprisesDataProvider api={httpRelatedEnterprisesApi}>
            <PromotionBilleablesProvider
                promotion={{
                    data: {
                        selloValorizaScore: promotion.puntuacionSelloValoriza,
                        selloValorizaValorizationTarget: promotion.valorizacion,
                        isDemo: promotion.isDemo
                    },
                    actions: {
                        updatePromotion: (params) => {
                            setPromotion({
                                ...promotion,
                                puntuacionSelloValoriza: params.selloValorizaScore,
                                valorizacion: params.selloValorizaValorizationTarget
                            });
                        }
                    }
                }}
                api={httpBilleablesApi}
            >
                <Wrapper>
                    {!props.layoutless && (
                        <TituloH1
                            titulo={
                                props.match.params.id
                                    ? t('titleEdit', { ns: 'newPromotion' })
                                    : t('title', { ns: 'newPromotion' })
                            }
                        />
                    )}
                    <Stepper
                        steps={steps}
                        setCurrentStep={handleStepChange}
                        currentStep={currentStep}
                        draftButton={false}
                        finalButton={false}
                    >
                        {loading && <Spinner />}
                        {message?.text && (
                            <ResultadoCarga text={message.text} success={message.success} setMessage={setMessage} />
                        )}
                        <StepperContent
                            {...{
                                setPromotion,
                                promotion,
                                handleInputChange,
                                validation,
                                submitting,
                                messageNotComplete,
                                direction,
                                setDirection,
                                setUsers,
                                users,
                                currentStep,
                                createPromotion
                            }}
                        />
                    </Stepper>
                </Wrapper>
            </PromotionBilleablesProvider>
        </RelatedEnterprisesDataProvider>
    );
};
export default withAuth(NewPromotion);
